/* App.css */

html {background-color: #1F1B24} 
.app {
  position: relative;
  font-family: "Poppins", sans-serif;
}

.page-enter {
  opacity: 0;
}

.page-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}


.home-background {
  width: 100%;
  margin: 0px;
  width: 100%;
  height: 100vh;
  background-image: url('./boardPics/hazards_cropped.jpg');
  background-repeat: no-repeat;
 
  display: flex;
  justify-content: center;
  align-items: center;
}


.mobile-background {
  width: auto;
  height: 100vh;
  background-image: url('./boardPics/mobileHome.JPG');
  background-size: 100%;
  background-repeat: no-repeat;
 
  display: flex;
  justify-content: center;
  align-items: center;
}
/* App.css */
.navbar {
  background-color: hsla(0, 10%, 90%, 0);
  text-align: right;
  height: 100%;
  padding: 10px 0px 20px 0px;
  margin: 0px;
  font-size: 1.25em;
  text-decoration: none;
  font-family: "Poppins", sans-serif;


}
.nav.navbar {
  margin-bottom: 0px;
}
.navbar a {
  text-decoration: none;
  color:  hsl(210, 8%, 14%);
  padding: 8px;
  margin-bottom: 0px;
}

.navbar a:hover {
  color: #00bcd4;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

