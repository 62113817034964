input{
  border-bottom: 2px solid black;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 1%;
  margin: 100px;
  width:75%
}

textarea{
  border-bottom: 2px solid black;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin:10%;
  width:75%
}
.inputContainer{
  margin: 20px 0px 0px 0px;
}

input[type="radio"] {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 2px solid #1FBED6;
  background-color: white;
  -webkit-appearance: none; /*to disable the default appearance of radio button*/
  -moz-appearance: none;
}

input[type="radio"]:focus { /*no need, if you don't disable default appearance*/
  outline: none; /*to remove the square border on focus*/
}

input[type="radio"]:checked { /*no need, if you don't disable default appearance*/
  background-color: #1FBED6;
}

input[type="radio"]:checked ~ span:first-of-type {
  color: white;
}

label span:first-of-type {
  position: relative;
  left: -27px;
  font-size: 15px;
  color: #1FBED6;
}

label span {
  position: relative;
  top: -12px;
}