/* ImageWithCaption.css */
.image-container {
    width: auto; /* Set the desired width */
    height: 20%; /* Set the desired height */
    margin: 20px;
    
  }
  
  img {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease; /* Add a smooth transition effect */
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }
  
  .caption {
    position: block;
    font-size: .750em;;
    width: 100%;
    background: rgba(77, 73, 78, 0.826); /* Add a semi-transparent background to the caption */
    color: white;
    padding: 10px;
    text-align: center;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }
  


.image-link {
  display: block;
  width: 100%;
  height: 100%;
}

